import Axios, {AxiosRequestConfig} from 'axios';
import AuthStorage from '../storage/AuthStorage';
import {CSV_ACCEPT_TYPE} from '../../types/TAcceptType';
import {handleError} from './utils';

export default abstract class HttpService {
    private backendHost = process.env.REACT_APP_API_ENDPOINT;
    public requestConfig: Partial<AxiosRequestConfig> = {};
    public prefix = '/';

    protected constructor(prefix: string) {
        this.prefix = prefix;
        this.requestConfig.baseURL = `${this.backendHost}/${this.prefix}`;

        Axios.interceptors.response.use(
            (response) => {
                response.data = this.transformObjectKeys(response.data);
                return response;
            },
            (error) => {
                error.response.data = this.transformObjectKeys(error.response.data);
                return Promise.reject(error);
            }
        );
    }

    private snakeToCamel(str: string): string {
        return str.replace(/([-_][a-z])/g, (group) =>
            group.toUpperCase().replace('-', '').replace('_', '')
        );
    }

    private transformObjectKeys(obj: any): any {
        if (!obj || typeof obj !== 'object') {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map((item) => this.transformObjectKeys(item));
        }

        return Object.keys(obj).reduce((result: any, key) => {
            const camelCaseKey = this.snakeToCamel(key);
            const value: any = obj[key];
            result[camelCaseKey] = this.transformObjectKeys(value);
            return result;
        }, {});
    }

    protected generateDefaultConfig() : Partial<AxiosRequestConfig> {
        const accessToken = AuthStorage.accessToken();
        if (accessToken) {
            let token = accessToken.accessToken;
            if (!token.startsWith('Bearer')) {
                token = `Bearer ${token}`;
            }
            return {
                ...this.requestConfig,
                headers: {
                    ...this.requestConfig.headers,
                    'Authorization': token,
                },
            };
        }
        return this.requestConfig;
    }

    public async get(
        url: string,
        requestConfig: Partial<AxiosRequestConfig> = {}
    ): Promise<any> {
        return await Axios.get(url, {
            ...this.generateDefaultConfig(),
            ...requestConfig,
        });
    }

    public async post(
        url: string,
        data: Record<string, unknown>,
        requestConfig: Partial<AxiosRequestConfig> = {}
    ): Promise<any> {
        return await Axios.post(url,
            {...data},
            {...this.generateDefaultConfig(), ...requestConfig}
        );
    }

    public async put(
        url: string,
        data: Record<string, unknown>,
        requestConfig: Partial<AxiosRequestConfig> = {}
    ): Promise<any> {
        return await Axios.put(url, data, {
            ...this.generateDefaultConfig(),
            ...requestConfig,
        });
    }

    public async patch(
        url: string,
        data: Record<string, unknown>,
        requestConfig: Partial<AxiosRequestConfig> = {}
    ): Promise<any> {
        return await Axios.patch(url, data, {
            ...this.generateDefaultConfig(),
            ...requestConfig,
        });
    }

    public async delete(
        url: string,
        requestConfig: Partial<AxiosRequestConfig> = {}
    ): Promise<any> {
        return await Axios.delete(url, {
            ...this.generateDefaultConfig(),
            ...requestConfig,
        });
    }

    protected async generateCSV(url: string, fileNameType: 'campaigns'|'predictions'|'companies'|'subscriptions'|'users'): Promise<any> {
        const requestConfig = this.generateDefaultConfig();
        requestConfig.headers = {
            ...requestConfig.headers,
            Accept: CSV_ACCEPT_TYPE,
        };
        try {
            const csvData = (await this.get(url, requestConfig)).data;
            if (csvData) {
                const downloadUrl = window.URL.createObjectURL(new Blob([csvData]));
                const link = document.createElement('a');
                const time = new Date();
                const fileName = `Feeder_${fileNameType}_report_${time.toLocaleDateString().replaceAll('/', '-')}.csv`;
                link.href = downloadUrl;
                link.setAttribute('download', fileName);
                document.body.append(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(downloadUrl);
            }
        }
        catch (err) {
            handleError(err);
        }

        return null;
    }
}
