import React, {useEffect, useState} from 'react';
import AdminLayout from '../../layouts/AdminLayout/AdminLayout';
import TCampaign from '../../types/TCampaign';
import CampaignsService from '../../services/http/CampaignService';
import CampaignsTable from '../../components/Elements/CampaignsTable/CampaignsTable';
import Notification from '../../components/Common/Notification/Notification';
import {LocalLoader} from '../../components/Common/Loader/Loader';
import CampaignsListProvider from '../../providers/CampaignsListProvider';
import ExportData from '../../components/Common/ExportData/ExportData';

const Campaigns = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [campaigns, setCampaigns] = useState<TCampaign[]>([]);

    const loadCampaigns = async () => {
        setIsLoading(true);
        try {
            const campaigns = await CampaignsService.list();
            if (campaigns) {
                setCampaigns(campaigns);
            }
        }
        catch (err: any) {
            Notification.displayException(err);
        }
        finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadCampaigns();
    }, []);

    if (isLoading) {
        return (
            <AdminLayout>
                <div className="w-full text-center flex flex-row justify-center pt-10">
                    <LocalLoader></LocalLoader>
                </div>
            </AdminLayout>
        );
    }

    const handleClickReport = async () => {
        await CampaignsService.report();
    };

    return (
        <AdminLayout>
            <div className="h-full px-5 relative">
                <h1 className="font-bold text-5xl mb-5 text-center">Campañas</h1>
                <CampaignsListProvider.Provider value={[campaigns, setCampaigns]}>
                    <div className='overflow-y-auto scrollbar h-[750px]'>
                        <CampaignsTable campaigns={campaigns} />
                    </div>
                    {
                        campaigns.length > 0 && <ExportData className='absolute bottom-5' onClick={handleClickReport}/>
                    }
                </CampaignsListProvider.Provider>
            </div>
        </AdminLayout>
    );
};

export default Campaigns;
