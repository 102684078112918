import React, {ReactElement} from 'react';

interface IModal {
    children: React.ReactNode;
    open: boolean;
    onClickOutside: () => void;
}

const Modal = (props: IModal): ReactElement => {
    const { children, open, onClickOutside } = props;
    return (
        <div
            className={`transition-all duration-500 ease-linear fixed z-10 inset-0 overflow-y-auto ${open ? 'block' : 'hidden'}`}>
            <div className="flex items-center justify-center min-h-screen">
                <div
                    onClick={() => onClickOutside && onClickOutside() }
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"
                />
                <div className="text-black bg-white rounded-lg overflow-hidden shadow-xl transform transition-all duration-500 ease-in-out px-12 py-8 flex">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Modal;
