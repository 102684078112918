import React from 'react';
import TCompany from '../../../types/TCompany';
import BlockCompanyAction from '../Actions/BlockCompanyAction';
import AddReactionsCompanyAction from '../Actions/AddReactionsCompanyAction';
import moment from 'moment';
import AddPredictionsCompanyAction from '../Actions/AddPredictionsCompanyAction';
import AddCampaignsCompanyAction from '../Actions/AddCampaignsCompanyAction';

interface ICompaniesTable {
    companies: TCompany[]
}
interface ICompanyTableRow {
    company: TCompany
    className?: string
}

const CompanyTableRow = (props: ICompanyTableRow): JSX.Element => {
    const { company, className } = props;
    return (
        <tr className={className}>
            <td>{company.name}</td>
            <td colSpan={2}>{company.email}</td>
            <td>{company.phone}</td>
            <td>{company.reactions.used}/{company.reactions.left}</td>
            <td>{company.campaigns.used}/{company.campaigns.left}</td>
            <td>{company.predictions.used}/{company.predictions.left}</td>
            <td>
                {
                    company.activeSubscription ?
                        <p>{company.activeSubscription.package.name}</p> :
                        company.previousSubscription ?
                            <p>{company.previousSubscription?.package.name}</p> :
                            <p>Sin Suscripción</p>
                }
            </td>
            <td>
                {
                    company.activeSubscription?.lastSubscriptionRenewal ?
                        <p>{moment(company.activeSubscription?.lastSubscriptionRenewal).format('DD-MM-YYYY HH:mm')}</p> :
                        company.previousSubscription?.lastSubscriptionRenewal ?
                            <p>{moment(company.previousSubscription?.lastSubscriptionRenewal).format('DD-MM-YYYY HH:mm')}</p> :
                            <p>-</p>
                }
            </td>
            <td>
                {
                    company.hasPreviousSubscription && company.activeSubscription === null ?
                        <p>{moment(company.previousSubscription?.cancelledAt).format('DD-MM-YYYY HH:mm')}</p> :
                        <p>NO</p>
                }
            </td>
            <td className="flex flex-row items-center justify-center space-x-2 py-1">
                <AddReactionsCompanyAction company={company} />
                <AddPredictionsCompanyAction company={company} />
                <AddCampaignsCompanyAction company={company} />
                <BlockCompanyAction company={company} />
            </td>
        </tr>
    );
};

const CompaniesTable = (props: ICompaniesTable) => {
    const { companies } = props;
    return (
        <table cellSpacing={0} className="table-fixed w-full text-center border-separate">
            <thead>
                <tr>
                    <th>Nombre</th>
                    <th colSpan={2}>Email</th>
                    <th>Teléfono</th>
                    <th>Reacciones (usadas/restantes)</th>
                    <th>Campañas (usadas/restantes)</th>
                    <th>Predicciones (usadas/restantes)</th>
                    <th>Tipo Suscripción</th>
                    <th>Última renovación</th>
                    <th>Cancelada</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                {
                    companies.map((c, index) =>
                        <CompanyTableRow company={c} key={c.id} className={index % 2 === 0 ? 'bg-white bg-opacity-10' : ''}/>
                    )
                }
            </tbody>
        </table>
    );
};

export default CompaniesTable;
