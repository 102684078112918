import HttpService from './HttpService';
import TCompany from '../../types/TCompany';
import {handleError} from './utils';
import {DEFAULT_ACCEPT_TYPE, TAcceptType} from '../../types/TAcceptType';

class CompanyService extends HttpService {
    private static _instance: CompanyService;
    private constructor() {
        super('');
    }
    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    async list(acceptType: TAcceptType = DEFAULT_ACCEPT_TYPE): Promise<TCompany[] | null> {
        const requestConfig = this.generateDefaultConfig();
        requestConfig.headers = {
            ...requestConfig.headers,
            Accept: acceptType,
        };
        try {
            return (await this.get('/companies', requestConfig)).data;
        }
        catch (err) {
            handleError(err);
        }

        return null;
    }

    async patchLocked(data: { id: string, locked: boolean} ): Promise<void> {
        const { id, locked } = data;
        const req = {
            locked,
        };
        try {
            await this.patch(`/companies/${id}`, req, this.requestConfig);
        }
        catch (err) {
            handleError(err);
        }
    }

    async report(): Promise<void> {
        try {
            await this.generateCSV('/companies', 'companies');
        }
        catch (error) {
            handleError(error);
        }
    }
}

export default CompanyService.instance;
