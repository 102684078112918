import React from 'react';
import {ArrowDownTrayIcon} from '@heroicons/react/24/solid';
import ActionButton from '../ActionButton/ActionButton';
interface IExportData {
    label?: string,
    className?: string,
    onClick?: () => void,
}
const ExportData = (props: IExportData) => {
    const { label = 'Exportar Datos', className, onClick } = props;

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <div className={className}>
            <ActionButton title={label} className='flex flex-row items-center bg-blue-500 rounded-xl p-2 cursor-pointer hover:bg-blue-700' onClick={handleClick}>
                {label}
                <ArrowDownTrayIcon className='ml-2 h-8 h-8' />
            </ActionButton>
        </div>
    );
};

export default ExportData;
