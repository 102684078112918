import React, {useContext, useState} from 'react';
import {TrashIcon} from '@heroicons/react/24/solid';
import ActionButton from '../../Common/ActionButton/ActionButton';
import TPrediction from '../../../types/TPrediction';
import PredictionsService from '../../../services/http/PredictionService';
import Modal from '../../Common/Modal/Modal';
import ConfirmButton from '../../Common/ConfirmButton/ConfirmButton';
import CancelButton from '../../Common/CancelButton/CancelButton';
import {LocalLoader} from '../../Common/Loader/Loader';
import Notification from '../../Common/Notification/Notification';
import PredictionsListProvider from '../../../providers/PredictionsListProvider';
import PredictionService from '../../../services/http/PredictionService';

interface IDeletePredictionAction {
    prediction: TPrediction,
}
const DeletePredictionAction = (props: IDeletePredictionAction) => {
    const { prediction } = props;
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [, setPredictions] = useContext(PredictionsListProvider);
    const [isDeleting, setIsDeleting] = useState(false);

    const removePrediction = async () => {
        setIsDeleting(true);
        try {
            await PredictionsService.remove(prediction);
            setModalOpen(false);
            await reloadPredictionsList();
            Notification.display('success', 'Predicción eliminada');
        }
        catch (err: any) {
            Notification.displayException(err);
        }
        finally {
            setIsDeleting(false);
        }
    };

    const reloadPredictionsList = async () => {
        const predictions = await PredictionService.list();
        setPredictions(predictions);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const openModal = () => {
        setModalOpen(true);
    };

    return (
        <>
            <ActionButton
                title={'Eliminar predicción'}
                onClick={openModal}
                className="bg-red-500 hover:bg-red-700 cursor-pointer rounded"
            >
                <TrashIcon className="h-6 w-6" />
            </ActionButton>
            <Modal
                open={modalOpen}
                onClickOutside={closeModal}
            >
                <div className="flex flex-col">
                    <p>Estas seguro que quieres eliminar esta predicción?</p>

                    <div className="flex flex-row justify-around mt-4">
                        <ConfirmButton onClick={removePrediction} disabled={isDeleting} />
                        <CancelButton onClick={closeModal} disabled={isDeleting} />
                    </div>
                    {
                        isDeleting &&
                        <div className="flex flex-col justify-center items-center text-center mt-4 space-y-4">
                            <p>Eliminando ...</p>
                            <LocalLoader />
                        </div>
                    }
                </div>
            </Modal>
        </>
    );
};

export default DeletePredictionAction;
