import ActionButton from '../../Common/ActionButton/ActionButton';
import TCompany from '../../../types/TCompany';
import React, {useContext, useState} from 'react';
import Modal from '../../Common/Modal/Modal';
import PackagesService from '../../../services/http/PackagesService';
import Notification from '../../Common/Notification/Notification';
import CompaniesListProvider from '../../../providers/CompaniesListProvider';
import CompanyService from '../../../services/http/CompanyService';

interface IAddCampaignsCompanyAction {
    company: TCompany;
}

const AddCampaignsCompanyAction = (props: IAddCampaignsCompanyAction): JSX.Element => {
    const [, setCompanies] = useContext(CompaniesListProvider);
    const [openModal, setOpenModal] = useState<boolean>(false);

    const addCampaigns = async (numberOfCampaigns: number) => {
        try {
            await PackagesService.addCampaignsToSubscription({companyId: props.company.id, amount: numberOfCampaigns});
            Notification.display('success', 'Campañas agregadas correctamente');
            await reloadList();
            setOpenModal(false);
        }
        catch (err: any) {
            Notification.displayException(err);
        }
    };

    const reloadList = async () => {
        try {
            const companies = await CompanyService.list();
            if (companies) {
                setCompanies(companies);
            }
        }
        catch (err: any) {
            Notification.displayException(err);
        }
    };

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;
        const formData = new FormData(form);
        const amount = Number(formData.get('campaigns'));
        if (amount > 0) {
            await addCampaigns(amount);
        }
        else {
            Notification.display('error', 'El número de campañas debe ser mayor a 0');
        }
    };

    return (
        <>
            <ActionButton
                title="Agregar campañas"
                onClick={() => {
                    setOpenModal(true);
                }}
                className="border bg-transparent uppercase font-bold"
            >
                C+
            </ActionButton>
            <Modal
                open={openModal}
                onClickOutside={() => {
                    setOpenModal(false);
                }}
            >
                <div className="flex flex-col space-y-4">
                    <h2 className="border-b py-1 ">Agregar campañas a pelo</h2>
                    <form className="flex flex-col space-y-4" onSubmit={onSubmit}>
                        <div className="flex flex-row space-x-4 items-center">
                            <label htmlFor="reactions" className="">Campañas</label>
                            <input type={'number'} name={'campaigns'} className="w-44 border rounded text-center" />
                            <button title={'Agregar campañas'} className="rounded transition-all duration-500 ease-linear p-1 border bg-transparent hover:bg-gray-400">
                                Agregar Campañas
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
        </>
    );
};

export default AddCampaignsCompanyAction;
