import React from 'react';
import {Link} from 'react-router-dom';
import {Paths} from '../../constants/Paths';

const NotFound = () => {
    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <div className="p-8 bg-white rounded-lg shadow-md text-center">
                <h1 className="text-6xl text-red-500 font-semibold mb-4">404</h1>
                <p className="text-gray-700 text-xl mb-8">Oops! Página no encontrada</p>
                <Link to={Paths.home} className="text-blue-500 hover:underline">Ir a Inicio</Link>
            </div>
        </div>
    );
};

export default NotFound;
