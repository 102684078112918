import {ReactElement, useContext, useEffect} from 'react';
import UserProvider from '../../../providers/UserProvider';
import {useNavigate} from 'react-router-dom';
import {Paths} from '../../../constants/Paths';

const UnprotectedPage = (props:{children: ReactElement}) => {
    const [user] = useContext(UserProvider);
    const navigate = useNavigate();

    useEffect(() => {
        if (user !== null && user.admin === true) {
            return navigate(Paths.home);
        }
    }, [user]);

    return props.children;
};

export default UnprotectedPage;
