import React, {useContext, useState} from 'react';
import {TrashIcon} from '@heroicons/react/24/solid';
import ActionButton from '../../Common/ActionButton/ActionButton';
import TCampaign from '../../../types/TCampaign';
import CampaignsService from '../../../services/http/CampaignService';
import Modal from '../../Common/Modal/Modal';
import ConfirmButton from '../../Common/ConfirmButton/ConfirmButton';
import CancelButton from '../../Common/CancelButton/CancelButton';
import CampaignsListProvider from '../../../providers/CampaignsListProvider';
import {LocalLoader} from '../../Common/Loader/Loader';
import Notification from '../../Common/Notification/Notification';

interface IDeleteCampaignAction {
    campaign: TCampaign,
}
const DeleteCampaignAction = (props: IDeleteCampaignAction) => {
    const { campaign } = props;
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [, setCampaigns] = useContext(CampaignsListProvider);
    const [isDeleting, setIsDeleting] = useState(false);

    const removeCampaign = async () => {
        setIsDeleting(true);
        try {
            await CampaignsService.remove(campaign);
            setModalOpen(false);
            await reloadCampaignsList();
            Notification.display('success', 'Campaña eliminada');
        }
        catch (err: any) {
            Notification.displayException(err);
        }
        finally {
            setIsDeleting(false);
        }
    };

    const reloadCampaignsList = async () => {
        const campaigns = await CampaignsService.list();
        setCampaigns(campaigns);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const openModal = () => {
        setModalOpen(true);
    };

    return (
        <>
            <ActionButton
                title={'Eliminar campaña'}
                onClick={openModal}
                className="bg-red-500 hover:bg-red-700 cursor-pointer rounded"
            >
                <TrashIcon className="h-6 w-6" />
            </ActionButton>
            <Modal
                open={modalOpen}
                onClickOutside={closeModal}
            >
                <div className="flex flex-col">
                    <p>Estas seguro que quieres eliminar esta campaña?</p>
                    <p className='text-gray-400 italic'>Se eliminaran todas la reacciones asociadas</p>

                    <div className="flex flex-row justify-around mt-4">
                        <ConfirmButton onClick={removeCampaign} disabled={isDeleting} />
                        <CancelButton onClick={closeModal} disabled={isDeleting} />
                    </div>
                    {
                        isDeleting &&
                        <div className="flex flex-col justify-center items-center text-center mt-4 space-y-4">
                            <p>Eliminando ...</p>
                            <LocalLoader />
                        </div>
                    }
                </div>
            </Modal>
        </>
    );
};

export default DeleteCampaignAction;
