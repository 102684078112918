import React, {useEffect, useState} from 'react';
import AdminLayout from '../../layouts/AdminLayout/AdminLayout';
import PredictionsTable from '../../components/Elements/PredictionsTable/PredictionsTable';
import {LocalLoader} from '../../components/Common/Loader/Loader';
import Notification from '../../components/Common/Notification/Notification';
import PredictionsListProvider from '../../providers/PredictionsListProvider';
import PredictionService from '../../services/http/PredictionService';
import TPrediction from '../../types/TPrediction';
import ExportData from '../../components/Common/ExportData/ExportData';

const Predictions = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [predictions, setPredictions] = useState<TPrediction[]>([]);

    const loadPredictions = async () => {
        setIsLoading(true);
        try {
            const predictions = await PredictionService.list();
            if (predictions) {
                setPredictions(predictions);
            }
        }
        catch (err: any) {
            Notification.displayException(err);
        }
        finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadPredictions();
    }, []);

    if (isLoading) {
        return (
            <AdminLayout>
                <div className="w-full text-center flex flex-row justify-center pt-10">
                    <LocalLoader></LocalLoader>
                </div>
            </AdminLayout>
        );
    }

    const handleClickCSV = async () => {
        await PredictionService.report();
    };

    return (
        <AdminLayout>
            <div className="h-full px-5 relative">
                <h1 className="font-bold text-5xl mb-5 text-center">Predicciones</h1>
                <PredictionsListProvider.Provider value={[predictions, setPredictions]}>
                    <div className='overflow-y-auto scrollbar h-[750px]'>
                        <PredictionsTable predictions={predictions} />
                    </div>
                    {
                        predictions.length > 0 && <ExportData className='absolute bottom-5' onClick={handleClickCSV}/>
                    }
                </PredictionsListProvider.Provider>
            </div>
        </AdminLayout>
    );
};

export default Predictions;
