import ActionButton from '../../Common/ActionButton/ActionButton';
import TCompany from '../../../types/TCompany';
import React, {useContext, useEffect, useState} from 'react';
import Modal from '../../Common/Modal/Modal';
import TPackage from '../../../types/TPackage';
import PackagesService from '../../../services/http/PackagesService';
import Notification from '../../Common/Notification/Notification';
import CompaniesListProvider from '../../../providers/CompaniesListProvider';
import CompanyService from '../../../services/http/CompanyService';

interface IAddReactionsCompanyAction {
    company: TCompany;
}

interface IOneTimePackage {
    pack: TPackage;
    onAdd: (pack: TPackage) => void;
}

const OneTimePackage = (props: IOneTimePackage): JSX.Element => {
    const { pack, onAdd } = props;

    return (
        <div className="flex flex-row space-x-4 border-b items-center py-1">
            <div className="flex flex-col w-14">
                <span className="font-bold">{pack.name}</span>
            </div>
            <div className="flex flex-col">
                <span className="font-bold">{pack.analysisPerMonth}</span>
                <span className="text-sm">Reacciones</span>
            </div>
            <div className="flex-grow" />
            <ActionButton onClick={() => onAdd(pack)} title="Agregar paquete de reacciones" className="border bg-transparent hover:bg-gray-400">
                Agregar Paquete
            </ActionButton>
        </div>
    );
};

const AddReactionsCompanyAction = (props: IAddReactionsCompanyAction): JSX.Element => {
    const [, setCompanies] = useContext(CompaniesListProvider);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [oneTimePackages, setOneTimePackages] = useState<TPackage[]>([]);
    const addReactionPackage = async (pack: TPackage) => {
        try {
            await PackagesService.addReactionPackageToCompany({companyId: props.company.id, packageId: pack.id});
            Notification.display('success', 'Paquete agregado correctamente');
            await reloadList();
            setOpenModal(false);
        }
        catch (err: any) {
            Notification.displayException(err);
        }
    };

    const addReactions = async (numberOfReactions: number) => {
        try {
            await PackagesService.addReactionsToSubscription({companyId: props.company.id, amount: numberOfReactions});
            Notification.display('success', 'Reacciones agregadas correctamente');
            await reloadList();
            setOpenModal(false);
        }
        catch (err: any) {
            Notification.displayException(err);
        }
    };

    const reloadList = async () => {
        try {
            const companies = await CompanyService.list();
            if (companies) {
                setCompanies(companies);
            }
        }
        catch (err: any) {
            Notification.displayException(err);
        }
    };

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;
        const formData = new FormData(form);
        const numberOfReactions = Number(formData.get('reactions'));
        if (numberOfReactions > 0) {
            await addReactions(numberOfReactions);
        }
        else {
            Notification.display('error', 'El número de reacciones debe ser mayor a 0');
        }
    };

    const loadOneTimePackages = async () => {
        try {
            const oneTimePackages = await PackagesService.listReactionPackages();
            if (oneTimePackages) {
                setOneTimePackages(oneTimePackages);
            }
        }
        catch (err: any) {
            Notification.displayException(err);
        }
    };

    useEffect(() => {
        loadOneTimePackages();
    }, []);

    return (
        <>
            <ActionButton
                title="Agregar reacciones"
                onClick={() => {
                    setOpenModal(true);
                }}
                className="border bg-transparent uppercase font-bold"
            >
                R+
            </ActionButton>
            <Modal
                open={openModal}
                onClickOutside={() => {
                    setOpenModal(false);
                }}
            >
                <div className="flex flex-col space-y-4">
                    <h2 className="border-b pb-1">Agregar Paquete de reacciones</h2>
                    <div className="flex flex-col">
                        {
                            oneTimePackages.map((p) => <OneTimePackage pack={p} key={p.id} onAdd={addReactionPackage} />)
                        }
                    </div>
                    <h2 className="border-b py-1 ">Agregar reacciones a pelo</h2>
                    <form className="flex flex-col space-y-4" onSubmit={onSubmit}>
                        <div className="flex flex-row space-x-4 items-center">
                            <label htmlFor="reactions" className="">Reacciones</label>
                            <input type={'number'} name={'reactions'} className="w-44 border rounded text-center" />
                            <button title={'Agregar reacciones'} className="rounded transition-all duration-500 ease-linear p-1 border bg-transparent hover:bg-gray-400">
                                Agregar Reacciones
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
        </>
    );
};

export default AddReactionsCompanyAction;
