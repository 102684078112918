import {ReactNode} from 'react';
import { motion } from 'framer-motion';

interface IFloatingCard {
    children: ReactNode,
    minHeightPX: number,
    minWidthPX: number,
    animate?: boolean,
}
const FloatingCard = (props:IFloatingCard) => {
    if (props.animate) {
        return (
            <motion.div
                initial={{ y: '20%', opacity: '0%' }}
                animate={{ y: '0%', opacity: '100%' }}
                transition={{ duration: 1, delay: 0.25 }}
                exit={{ y: '20%', opacity: '0%' }}
                className="flex flex-col bg-white drop-shadow-xl rounded-lg" style={{minHeight: `${props.minHeightPX}px`, minWidth: `${props.minWidthPX}px`}}>
                {props.children}
            </motion.div>
        );
    }
    else {
        return (
            <div
                className="flex flex-col bg-white drop-shadow-lg rounded-lg" style={{minHeight: `${props.minHeightPX}px`, minWidth: `${props.minWidthPX}px`}}>
                {props.children}
            </div>
        );
    }
};

export default FloatingCard;
