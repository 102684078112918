import HttpService from './HttpService';
import {TAccessToken} from '../../types/TAccessToken';
class AuthService extends HttpService {
    private static _instance: AuthService;
    private constructor() {
        super('');
    }
    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    public async login(email: string, password: string): Promise<TAccessToken | null> {
        const request = {
            'grant_type': 'password',
            'email': email,
            'password': password,
        };
        try {
            return (await this.post('/tokens', request)).data;
        }
        catch (e) {
            return null;
        }
    }
}

export default AuthService.instance;
