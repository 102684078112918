import {Id, toast, TypeOptions} from 'react-toastify';
import ErrorException from '../../../services/exception/ErrorException';
import WarningException from '../../../services/exception/WarningException';
import SuccessException from '../../../services/exception/SuccessException';

class Notification {
    currentToast: Id = Math.random();

    public display(type: TypeOptions, text: string) {
        if (!toast.isActive(this.currentToast)) {
            this.currentToast = toast(text, {
                type: type,
                className: 'border-radius-5',
            });
        }
    }

    public displayException(err: ErrorException | WarningException | Error) {
        let type: TypeOptions;
        if (err instanceof ErrorException) {
            type = 'error';
        }
        else if (err instanceof WarningException) {
            type = 'warning';
        }
        else if (err instanceof SuccessException) {
            type = 'success';
        }
        else {
            type = 'error';
        }

        this.display(type, err.message);
    }
}

// eslint-disable-next-line
export default new Notification();
export { ToastContainer as NotificationContainer } from 'react-toastify';
