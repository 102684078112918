import HttpService from './HttpService';
import TPackage from '../../types/TPackage';
import {handleError} from './utils';

class PackagesService extends HttpService {
    private static _instance: PackagesService;
    private constructor() {
        super('');
    }
    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    public async listSubscriptionsPackages(): Promise<TPackage[] | null> {
        try {
            return (await this.get('/packages/subscriptions', this.requestConfig)).data;
        }
        catch (err) {
            handleError(err);
        }
        return null;
    }

    public async listReactionPackages(): Promise<TPackage[] | null> {
        try {
            return (await this.get('/packages/reactions', this.requestConfig)).data;
        }
        catch (err) {
            handleError(err);
        }
        return null;
    }

    public async addReactionPackageToCompany(data: { companyId: string, packageId: string} ) {
        const { companyId, packageId } = data;
        const req = {
            type: 'package',
            package_id: packageId,
        };
        try {
            await this.post(`/company/${companyId}/subscription/reactions`, req, this.requestConfig);
        }
        catch (err) {
            handleError(err);
        }
    }

    public async addReactionsToSubscription(data: { companyId: string, amount: number} ) {
        const { companyId, amount } = data;
        const req = {
            type: 'subscription',
            amount,
        };
        try {
            await this.post(`/company/${companyId}/subscription/reactions`, req, this.requestConfig);
        }
        catch (err) {
            handleError(err);
        }
    }

    public async addCampaignsToSubscription(data: { companyId: string; amount: number; }) {
        const { companyId, amount } = data;
        const req = {
            amount,
        };
        try {
            await this.post(`/company/${companyId}/subscription/campaigns`, req, this.requestConfig);
        }
        catch (err) {
            handleError(err);
        }
    }

    public async addPredictionsToSubscription(data: { companyId: string; amount: number; }) {
        const { companyId, amount } = data;
        const req = {
            amount,
        };
        try {
            await this.post(`/company/${companyId}/subscription/predictions`, req, this.requestConfig);
        }
        catch (err) {
            handleError(err);
        }
    }
}

export default PackagesService.instance;
