import React from 'react';

interface ICancelButton {
    onClick?: () => void,
    disabled?: boolean,
}
const CancelButton = (props: ICancelButton) => {
    const {onClick, disabled = false} = props;
    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <button disabled={disabled} className={`bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={handleClick}>
            Cancelar
        </button>
    );
};

export default CancelButton;
