import React, {ReactNode} from 'react';
import UnprotectedPage from '../../components/Common/UnprotectedPage/UnprotectedPage';
import {AnimatePresence} from 'framer-motion';
import {NotificationContainer} from '../../components/Common/Notification/Notification';

const LoginLayout = (props: { children: ReactNode }) => {
    return (
        <UnprotectedPage>
            <div className="relative h-screen w-screen flex flex-row x-space-0 overflow-hidden bg-indigo-50">
                <NotificationContainer />
                <div className="w-full h-full flex flex-col justify-center items-center">
                    <AnimatePresence mode={'wait'}>
                        {props.children}
                    </AnimatePresence>
                </div>
            </div>
        </UnprotectedPage>
    );
};

export default LoginLayout;
