import React, {useEffect, useState} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import Home from './pages/Home/Home';
import Users from './pages/Users/Users';
import Companies from './pages/Companies/Companies';
import Login from './pages/Login/Login';
import {Paths} from './constants/Paths';
import UserProvider from './providers/UserProvider';
import TUser from './types/TUser';
import UserService from './services/http/UserService';
import NotFound from './pages/NotFound/NotFound';
import Campaigns from './pages/Campaigns/Campaigns';
import Predictions from './pages/Predictions/Predictions';

const App = () => {
    const [user, setUser] = useState<TUser|null>(null);
    const handleUser = async () => {
        const user = await UserService.me();
        if (user) {
            setUser(user);
        }
    };

    useEffect(() => {
        handleUser();
    }, []);

    return (
        <UserProvider.Provider value={[user, setUser]}>
            <BrowserRouter>
                <Routes>
                    <Route index element={<Home />} />
                    <Route path={'/home'} element={<Home />} />
                    <Route path={Paths.login} element={<Login />} />
                    <Route path={Paths.users} element={<Users />} />
                    <Route path={Paths.companies} element={<Companies />} />
                    <Route path={Paths.campaigns} element={<Campaigns />} />
                    <Route path={Paths.predictions} element={<Predictions />} />
                    <Route path={Paths.notFound} element={<NotFound /> } />
                    <Route path='*' element={<Navigate to={Paths.notFound} />} />
                </Routes>
            </BrowserRouter>
        </UserProvider.Provider>
    );
};

export default App;
