import React from 'react';
import Sidebar from '../../components/Common/Sidebar/Sidebar';
import ProtectedPage from '../../components/Common/ProtectedPage/ProtectedPage';
import {NotificationContainer} from '../../components/Common/Notification/Notification';
import 'react-toastify/dist/ReactToastify.css';

interface IAdminLayout {
    children: React.ReactNode
}
const AdminLayout = (props: IAdminLayout) => {
    const { children } = props;

    return (
        <ProtectedPage>
            <div className="relative bg-slate-800 text-white h-screen flex flex-row scrollbar scrollbar-thumb-slate-500 scrollbar-track-slate-900 scrollbar-thin">
                <Sidebar/>
                <div className="my-5 flex flex-col w-full">
                    <main className="h-full mx-5">
                        <NotificationContainer />
                        {children}
                    </main>
                </div>
            </div>
        </ProtectedPage>
    );
};

export default AdminLayout;
