import React from 'react';
import './Loader.css';

export const LocalLoader: React.FC = () => (
    <div className="local-loader">
        <svg
            className="spinner"
            width="40px"
            height="40px"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                className="path stroke-current text-primary"
                strokeWidth="6"
                fill="none"
                strokeLinecap="round"
                cx="33"
                cy="33"
                r="30"
            ></circle>
        </svg>
    </div>
);

export const SpinnerLoader = () => {
    return <LocalLoader />;
};

const PageLoader: React.FC = () => (
    <div className="page-loader">
        <svg
            className="spinner"
            width="40px"
            height="40px"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                className="path"
                fill="none"
                strokeWidth="6"
                strokeLinecap="round"
                cx="33"
                cy="33"
                r="30"
            ></circle>
        </svg>
    </div>
);

export default PageLoader;
