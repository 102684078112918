import React from 'react';
import TCampaign from '../../../types/TCampaign';
import moment from 'moment';
import DeleteCampaignAction from '../Actions/DeleteCampaignAction';

interface ICampaignsTable {
    campaigns: TCampaign[]
}
const CampaignsTable = (props: ICampaignsTable) => {
    const { campaigns } = props;
    return (
        <table cellSpacing={0} className="table-fixed w-full text-center border-separate">
            <thead>
                <tr>
                    <th>Título</th>
                    <th>Compañia</th>
                    <th>Reacciones Usadas</th>
                    <th>Reacciones Máximas</th>
                    <th>Fecha Inicio</th>
                    <th>Fecha Fin</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                {
                    campaigns.map((c, index) =>
                        <tr key={index} className={index % 2 === 0 ? 'bg-white bg-opacity-10' : ''}>
                            <td>{c.title}</td>
                            <td>{c.company.name}</td>
                            <td>{c.countReactions}</td>
                            <td>{c.configuration.totalReactions}</td>
                            <td>{moment(c.configuration.startDate).format('DD-MM-YYYY')}</td>
                            <td>{moment(c.configuration.stopDate).format('DD-MM-YYYY')}</td>
                            <td>
                                <DeleteCampaignAction campaign={c} />
                            </td>
                        </tr>
                    )
                }
            </tbody>
        </table>
    );
};

export default CampaignsTable;
