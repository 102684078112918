import {useContext, useState} from 'react';
import LoginLayout from '../../layouts/LoginLayout/LoginLayout';
import FloatingCard from '../../components/Common/FloatingCard/FloatingCard';
import TextInput from '../../components/Common/Input/TextIn';
import {Cog8ToothIcon} from '@heroicons/react/24/solid';
import AuthService from '../../services/http/AuthService';
import AuthStorage from '../../services/storage/AuthStorage';
import UserService from '../../services/http/UserService';
import {Paths} from '../../constants/Paths';
import {useNavigate} from 'react-router-dom';
import Notification from '../../components/Common/Notification/Notification';
import UserProvider from '../../providers/UserProvider';

const Login = () => {
    const navigate = useNavigate();
    const [, setUser] = useContext(UserProvider);
    const [state, setState] = useState<
        'waiting'|'error'|'sending'
    >('waiting');

    const submitForm = async () => {
        try {
            const form = document.getElementById('loginForm') as HTMLFormElement;
            const formData = new FormData(form);
            const email = formData.get('email') as string;
            const password = formData.get('password') as string;

            setState('sending');
            const res = await AuthService.login(email, password);
            if (!res) {
                setState('error');
            }
            else {
                AuthStorage.saveAccessToken(res);
                const user = await UserService.me();
                setUser(user);
                if (user !== null && user.admin === true) {
                    return navigate(Paths.home);
                }
                else {
                    Notification.displayException(new Error('El usuario no es admin'));
                    setState('error');
                }
            }
        }
        catch (e: any) {
            Notification.display('error', e.message);
            setState('error');
        }
    };

    return (
        <LoginLayout>
            <FloatingCard minHeightPX={0} minWidthPX={400} animate={true}>
                <form id="loginForm" onSubmit={(e) => e.preventDefault()} autoComplete="off">
                    <div className="relative h-full flex flex-col items-center py-8 px-12">
                        <h1 className="text-2xl font-regular mb-10">Feeder Admin Dashboard</h1>
                        <TextInput name={'email'} placeHolder={'youremail@example.com'} type={'email'} />
                        <TextInput name={'password'} placeHolder={'*********'} type={'password'} />
                        <div className="mb-5" />
                        <button
                            onClick={submitForm}
                            className={
                                `absolute bottom-0 w-full h-10 uppercase text-sm font-bold transform-all duration-500 ease-in text-white rounded-br-lg rounded-bl-lg
                            ${
        state === 'waiting' ? 'bg-zinc-700 hover:bg-sky-700' :
            state === 'sending' ? 'bg-sky-700' : 'bg-red-800 hover:bg-sky-700'
        }`
                            }>
                            {state === 'sending'? <Cog8ToothIcon className="animate-spin h-6 w-6 m-auto" />: 'Log in'}
                        </button>
                    </div>
                </form>
            </FloatingCard>
        </LoginLayout>
    );
};
export default Login;
