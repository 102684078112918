import HttpService from './HttpService';
import {handleError} from './utils';
import TSubscription from '../../types/TSubscription';

class SubscriptionService extends HttpService {
    private static _instance: SubscriptionService;
    private constructor() {
        super('');
    }
    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    async list(): Promise<TSubscription[] | null> {
        try {
            return (await this.get('/subscriptions')).data;
        }
        catch (err) {
            handleError(err);
        }

        return null;
    }

    async report(): Promise<void> {
        try {
            await this.generateCSV('/subscriptions', 'subscriptions');
        }
        catch (error) {
            handleError(error);
        }
    }
}

export default SubscriptionService.instance;
