import ErrorException from '../exception/ErrorException';
import _ from 'lodash';

interface IError {
    message: string;
    code: number;
    type: string;
}
export function handleError(err: any): IError {
    let errorMessage: string;
    if (!err.response) {
        errorMessage = 'errors.unknown';
        throw new Error(errorMessage);
    }

    let errorType: string;
    if (Array.isArray(err.response.data)) {
        errorType = err.response.data.length > 0 ? err.response.data[0].errorType : 'errors.unknown';
    }
    else {
        errorType = err.response.data.errorType;
    }

    switch (err.response.status) {
    case 400:
    case 500:
    case 401:
    case 403:
    case 404:
    case 409:
        errorMessage = `${_.camelCase(errorType)}`;
        break;
    default:
        errorMessage ='errors.unknown';
    }

    throw new ErrorException(
        errorMessage,
        err.response.status,
        err.response.data.errorType
    );
}
