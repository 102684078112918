import AdminLayout from '../../layouts/AdminLayout/AdminLayout';
import CompaniesTable from '../../components/Elements/CompaniesTable/CompaniesTable';
import React, {useEffect, useState} from 'react';
import TCompany from '../../types/TCompany';
import CompanyService from '../../services/http/CompanyService';
import {LocalLoader} from '../../components/Common/Loader/Loader';
import Notification from '../../components/Common/Notification/Notification';
import CompaniesListProvider from '../../providers/CompaniesListProvider';
import ExportData from '../../components/Common/ExportData/ExportData';
import SubscriptionService from '../../services/http/SubscriptionService';

const Companies = () => {
    const [companies, setCompanies] = useState<TCompany[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const loadCompanies = async () => {
        setIsLoading(true);
        try {
            const companies = await CompanyService.list();
            if (companies) {
                setCompanies(companies);
            }
        }
        catch (err: any) {
            Notification.displayException(err);
        }
        finally {
            setIsLoading(false);
        }
    };

    const handleExportCompanies = async () => {
        await CompanyService.report();
    };

    const handleExportSubscriptions = async () => {
        await SubscriptionService.report();
    };

    useEffect(() => {
        loadCompanies();
    }, []);

    if (isLoading) {
        return (
            <AdminLayout>
                <div className="w-full text-center flex flex-row justify-center pt-10">
                    <LocalLoader></LocalLoader>
                </div>
            </AdminLayout>
        );
    }

    return (
        <AdminLayout>
            <div className="h-full px-5 relative">
                <h1 className="font-bold text-5xl mb-5 text-center">Empresas</h1>
                <CompaniesListProvider.Provider value={[companies, setCompanies]}>
                    <div className="overflow-y-auto scrollbar h-[750px]">
                        <CompaniesTable companies={companies} />
                    </div>
                    {
                        companies.length > 0 &&
                        <div className='absolute bottom-0 flex flex-row space-x-3'>
                            <ExportData
                                onClick={handleExportCompanies}
                                label='Exportar Empresas'
                            />
                            <ExportData
                                onClick={handleExportSubscriptions}
                                label='Exportar Suscripciones'
                            />
                        </div>
                    }
                </CompaniesListProvider.Provider>
            </div>
        </AdminLayout>
    );
};

export default Companies;
