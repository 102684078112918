import HttpService from './HttpService';
import TCampaign from '../../types/TCampaign';
import {DEFAULT_ACCEPT_TYPE, TAcceptType} from '../../types/TAcceptType';
import {handleError} from './utils';

class CampaignService extends HttpService {
    private static _instance: CampaignService;

    public constructor() {
        super('');
    }

    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    async fetchCampaign(campaignId: string): Promise<TCampaign | null> {
        try {
            return (await this.get(`/campaigns/${campaignId}`, this.requestConfig)).data;
        }
        catch (err) {
            handleError(err);
        }

        return null;
    }

    async list(acceptType: TAcceptType = DEFAULT_ACCEPT_TYPE): Promise<TCampaign[] | null> {
        const requestConfig = this.generateDefaultConfig();
        requestConfig.headers = {
            ...requestConfig.headers,
            Accept: acceptType,
        };
        try {
            return (await this.get('/campaigns', requestConfig)).data;
        }
        catch (err) {
            handleError(err);
        }

        return null;
    }

    async remove(campaign: TCampaign): Promise<void> {
        try {
            await this.delete(`/campaigns/${campaign.id}`, this.requestConfig);
        }
        catch (err) {
            handleError(err);
        }
    }

    async overviewReport(campaign: TCampaign): Promise<any> {
        try {
            return (await this.get(`/campaign/${campaign.id}/overview`, this.requestConfig)).data;
        }
        catch (e) {
            handleError(e);
        }
    }

    async report(): Promise<void> {
        try {
            await this.generateCSV('/campaigns', 'campaigns');
        }
        catch (error) {
            handleError(error);
        }
    }
}

export default CampaignService.instance;
