import React from 'react';
import TUser from '../../../types/TUser';

interface IUsersTable {
    users: TUser[]
}
const UsersTable = (props: IUsersTable) => {
    const { users } = props;
    return (
        <table cellSpacing={0} className="table-fixed w-full text-center border-separate">
            <thead>
                <tr>
                    <th>Nombre</th>
                    <th>Email</th>
                    <th>Teléfono</th>
                    <th>Empresa</th>
                    <th>Idioma</th>
                </tr>
            </thead>
            <tbody>
                {
                    users.map((u, index) =>
                        <tr key={index} className={index % 2 === 0 ? 'bg-white bg-opacity-10' : ''}>
                            <td>{u.name}</td>
                            <td>{u.email}</td>
                            <td>{u.phone}</td>
                            <td>{u.company?.name}</td>
                            <td>{u.language}</td>
                        </tr>
                    )
                }
            </tbody>
        </table>
    );
};

export default UsersTable;
