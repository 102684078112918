import React from 'react';

interface ActionButtonProps {
    onClick: () => void;
    children: React.ReactNode;
    title: string;
    className?: string;
}

const ActionButton = (props: ActionButtonProps): JSX.Element => {
    const { onClick, children, className, title } = props;

    return (
        <button
            title={title}
            className={`rounded transition-all duration-500 ease-linear p-1 ${className}`}
            onClick={onClick}
        >
            {children}
        </button>
    );
};

export default ActionButton;
