import React from 'react';
import mergeClasses from '../../../utils/TailwindMergeClasses';
import {BuildingStorefrontIcon, VideoCameraIcon, UsersIcon, PresentationChartLineIcon} from '@heroicons/react/24/solid';
import {Link} from 'react-router-dom';
import {Paths} from '../../../constants/Paths';

interface ISidebar {
    className?: string
}

interface SidebarElement {
    path: string,
    icon: JSX.Element
    label?: string,
}

const Sidebar = (props: ISidebar) => {
    let { className = '' } = props;
    className = mergeClasses( 'bg-slate-900 my-5 mx-3 rounded-2xl flex flex-col', className);

    const currentPath = window.location.pathname;

    const SidebarElements:SidebarElement[] = [
        {
            path: Paths.home,
            icon: <img src='/favicon.ico' alt='Feeder' className='w-full'/>,
        },
        {
            path: Paths.users,
            icon: <UsersIcon />,
            label: 'Usuarios',
        },
        {
            path: Paths.companies,
            icon: <BuildingStorefrontIcon />,
            label: 'Empresas',
        },
        {
            path: Paths.campaigns,
            icon: <VideoCameraIcon />,
            label: 'Campañas',
        },
        {
            path: Paths.predictions,
            icon: <PresentationChartLineIcon />,
            label: 'Predicciones',
        },
    ];

    return (
        <div className={className}>
            <nav>
                <ul>
                    {
                        SidebarElements.map((e, index) =>
                            <li key={index} className={`hover:bg-slate-700 p-3 ${currentPath === e.path? 'bg-slate-700' : ''}`}>
                                <Link to={e.path} className='flex flex-col text-center'>
                                    {e.icon}
                                    {e.label}
                                </Link>
                            </li>
                        )
                    }
                </ul>
            </nav>
        </div>
    );
};

export default Sidebar;
