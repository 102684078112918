import React from 'react';

interface IBasicCard {
    label: string,
    value: any,
    value2?: any
}
const BasicCard = (props: IBasicCard) => {
    const { label, value, value2} = props;
    return (
        <div className="flex flex-col items-center justify-center bg-gradient-to-r from-blue-400 to-purple-600 p-6 rounded-lg shadow-lg w-full mx-auto mt-8 text-white text-center">
            <p className="text-2xl font-semibold">{label}</p>
            <p className="text-4xl font-bold mt-2">{value}</p>
            {value2 && <p className="text-4xl font-bold mt-2">{value2}</p>}
        </div>
    );
};

export default BasicCard;
