import HttpService from './HttpService';
import TUser from '../../types/TUser';
import {DEFAULT_ACCEPT_TYPE, TAcceptType} from '../../types/TAcceptType';
import {handleError} from './utils';

class UserService extends HttpService {
    private static _instance: UserService;
    private constructor() {
        super('');
    }
    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    public async me(): Promise<TUser|null> {
        try {
            return (await this.get('/me', this.requestConfig)).data;
        }
        catch (e) {
            handleError(e);
        }
        return null;
    }

    async list(acceptType: TAcceptType = DEFAULT_ACCEPT_TYPE): Promise<TUser[]|null> {
        const requestConfig = this.generateDefaultConfig();
        requestConfig.headers = {
            ...requestConfig.headers,
            Accept: acceptType,
        };

        try {
            return (await this.get('/users', requestConfig)).data;
        }
        catch (err) {
            handleError(err);
        }

        return null;
    }

    async report(): Promise<void> {
        try {
            await this.generateCSV('/users', 'users');
        }
        catch (error) {
            handleError(error);
        }
    }
}

export default UserService.instance;
