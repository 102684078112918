import React, {useContext} from 'react';
import TCompany from '../../../types/TCompany';
import Notification from '../../Common/Notification/Notification';
import CompanyService from '../../../services/http/CompanyService';
import {LockClosedIcon, LockOpenIcon} from '@heroicons/react/24/outline';
import ActionButton from '../../Common/ActionButton/ActionButton';
import CompaniesListContext from '../../../providers/CompaniesListProvider';

interface IBlockCompanyAction {
    company: TCompany;
}

const BlockCompanyAction = (props: IBlockCompanyAction): JSX.Element => {
    const { company } = props;
    const [, setCompanies] = useContext(CompaniesListContext);

    const handleLockCompany = async ( id: string)=> {
        await handleBlockStateChange(id, true, 'Empresa bloqueada con éxito');
    };

    const handleUnlockCompany = async ( id: string)=> {
        await handleBlockStateChange(id, false, 'Empresa desbloqueada con éxito');
    };

    const handleBlockStateChange = async (id: string, newLockState: boolean, successMessage: string) => {
        try {
            await CompanyService.patchLocked({
                id,
                locked: newLockState,
            });
            Notification.display('success', successMessage);
            await reloadList();
        }
        catch (err: any) {
            Notification.displayException(err);
        }
    };

    const reloadList = async () => {
        try {
            const companies = await CompanyService.list();
            if (companies) {
                setCompanies(companies);
            }
        }
        catch (err: any) {
            Notification.displayException(err);
        }
    };

    const clickAction = () => {
        if (company.locked) {
            handleUnlockCompany(company.id).then();
        }
        else {
            handleLockCompany(company.id).then();
        }
    };

    return (
        <ActionButton
            title={company.locked ? 'Desbloquear empresa' : 'Bloquear empresa'}
            onClick={clickAction}
            className={company.locked ? 'bg-red-500' : 'bg-green-500'}
        >
            {company.locked ? <LockClosedIcon className="h-6 w-6" /> : <LockOpenIcon className="h-6 w-6" />}
        </ActionButton>
    );
};

export default BlockCompanyAction;
