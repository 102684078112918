import React from 'react';
import TPrediction from '../../../types/TPrediction';
import DeletePredictionAction from '../Actions/DeletePredictionAction';

interface IPredictionsTable {
    predictions: TPrediction[]
}


interface IPredictionsTableRow {
    prediction: TPrediction,
    className?: string,
}

const PredictionsTableRow = (props: IPredictionsTableRow) => {
    const {prediction, className = ''} = props;

    return (
        <tr className={className}>
            <td>{prediction.title}</td>
            <td>{prediction.company.name}</td>
            <td>{prediction.sector}</td>
            <td>{prediction.completed ? 'Sí' : 'No'}</td>
            <td>
                <DeletePredictionAction prediction={prediction}/>
            </td>
        </tr>
    );
};

const PredictionsTable = (props: IPredictionsTable) => {
    const { predictions } = props;
    return (
        <table cellSpacing={0} className="table-fixed w-full text-center border-separate">
            <thead>
                <tr>
                    <th>Título</th>
                    <th>Compañia</th>
                    <th>Sector</th>
                    <th>Predicción completada</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                {
                    predictions.map((p, index) =>
                        <PredictionsTableRow key={p.id} prediction={p} className={index % 2 === 0 ? 'bg-white bg-opacity-10' : ''} />
                    )
                }
            </tbody>
        </table>
    );
};

export default PredictionsTable;
