import {
    ReactElement,
    useContext,
} from 'react';
import {Navigate} from 'react-router-dom';
import {Paths} from '../../../constants/Paths';
import UserProvider from '../../../providers/UserProvider';

const ProtectedPage = (props: {children: ReactElement}) => {
    const [user] = useContext(UserProvider);

    if (!user?.admin) {
        return (
            <Navigate to={Paths.login} {... props} />
        );
    }

    return props.children;
};

export default ProtectedPage;
