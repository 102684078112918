import React from 'react';

interface IConfirmButton {
    onClick?: () => void,
    disabled?: boolean,
}
const ConfirmButton = (props: IConfirmButton) => {
    const {onClick, disabled = false} = props;
    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <button disabled={disabled} className={`bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={handleClick}>
            Confirmar
        </button>
    );
};

export default ConfirmButton;
