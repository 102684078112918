import {EnvelopeIcon, LockClosedIcon} from '@heroicons/react/24/outline';
import {ReactNode} from 'react';

interface ITextInput {
    name: string,
    placeHolder: string,
    type: 'text'|'password'|'email',
    icon?: ReactNode,
}
const TextInput = (props: ITextInput) => {
    return (
        <div className="h-10 bg-gray-200 rounded-sm drop-shadow-sm flex flex-row items-center mb-5">
            {props.type === 'email' ? <EnvelopeIcon className="w-6 h-6 m-2" /> : props.type === 'password' ? <LockClosedIcon className="w-6 h-6 m-2" />: null}
            <input className="bg-gray-200 h-8 border-transparent focus:outline-none appearance-none" name={props.name} type={props.type} placeholder={props.placeHolder}/>
        </div>
    );
};

export default TextInput;
