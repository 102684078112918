export enum ELocalStorageKeys {
    // eslint-disable-next-line no-unused-vars
    accessToken = 'accessToken',
}
export default abstract class LocalStorage {
    protected getItem(key: ELocalStorageKeys): string | null {
        return localStorage.getItem(key);
    }

    protected setItem(key: ELocalStorageKeys, value: string) {
        localStorage.setItem(key, value);
    }

    protected removeItem(key: ELocalStorageKeys) {
        localStorage.removeItem(key);
    }
}
