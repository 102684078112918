import React, {useEffect, useState} from 'react';
import AdminLayout from '../../layouts/AdminLayout/AdminLayout';
import BasicCard from '../../components/Common/BasicCard/BasicCard';
import UserService from '../../services/http/UserService';
import SubscriptionService from '../../services/http/SubscriptionService';
import CompanyService from '../../services/http/CompanyService';
import CampaignService from '../../services/http/CampaignService';
import PredictionService from '../../services/http/PredictionService';
import {LocalLoader} from '../../components/Common/Loader/Loader';
const Home = () => {
    const title = process.env.REACT_APP_PRODUCT_NAME || 'Feeder Admin Dashboard';
    const [users, setUsers] = useState<number>(0);
    const [activeSubscriptions, setActiveSubscriptions] = useState<number>(0);
    const [FreeSubscriptions, setFreeSubscriptions] = useState<number>(0);
    const [BasicSubscriptions, setBasicSubscriptions] = useState<number>(0);
    const [ProSubscriptions, setProSubscriptions] = useState<number>(0);
    const [companies, setCompanies] = useState<number>(0);
    const [campaigns, setCampaigns] = useState<number>(0);
    const [predictions, setPredictions] = useState<number>(0);
    const [cancelledCompanies, setCancelledCompanies] = useState<number>(0);
    const [companiesWithCampaigns, setCompaniesWithCampaigns] = useState<number>(0);
    const [totalCountReactions, setTotalCountReactions] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>();

    const loadUsers = async () => {
        const users = await UserService.list();
        if (users) {
            setUsers(users.length);
        }
    };

    const loadSubscriptions = async () => {
        const subscriptions = await SubscriptionService.list();
        if (subscriptions) {
            let active = 0;
            let free = 0;
            let basic = 0;
            let pro = 0;
            subscriptions.forEach((subscription) => {
                if (subscription.subscribedAt !== null && subscription.cancelledAt === null) {
                    active++;
                    switch (subscription.package.name.toLowerCase()) {
                    case 'free':
                        free++;
                        break;
                    case 'basic':
                        basic++;
                        break;
                    case 'pro':
                        pro++;
                        break;
                    default:
                        break;
                    }
                }
            });
            setActiveSubscriptions(active);
            setFreeSubscriptions(free);
            setBasicSubscriptions(basic);
            setProSubscriptions(pro);
        }
    };

    const loadCompanies = async () => {
        const companies = await CompanyService.list();
        let cancelledCompanies = 0;
        let totalCompaniesWithCampaigns = 0;
        if (companies) {
            setCompanies(companies.length);
            companies.forEach((company) => {
                if (company.previousSubscription && company.activeSubscription === null) {
                    cancelledCompanies++;
                }
                if (company.totalCountCampaigns > 0) {
                    totalCompaniesWithCampaigns++;
                }
            });
            setCancelledCompanies(cancelledCompanies);
            setCompaniesWithCampaigns(totalCompaniesWithCampaigns);
        }
    };

    const loadCampaigns = async () => {
        const campaigns = await CampaignService.list();
        let totalCountReactions = 0;
        if (campaigns) {
            setCampaigns(campaigns.length);
            campaigns.forEach((campaign) => {
                if (campaign.countReactions > 0) {
                    totalCountReactions += campaign.countReactions;
                }
            });
        }
        setTotalCountReactions(totalCountReactions);
    };

    const loadPredictions = async () => {
        const predictions = await PredictionService.list();
        if (predictions) {
            setPredictions(predictions.length);
        }
    };

    useEffect(() => {
        const loadData = async () => {
            setIsLoading(true);
            try {
                await loadUsers();
                await loadSubscriptions();
                await loadCompanies();
                await loadCampaigns();
                await loadPredictions();
            }
            catch (error) {
                console.error('Error loading data:', error);
            }
            finally {
                setIsLoading(false);
            }
        };
        loadData();
    }, []);

    if (isLoading) {
        return (
            <AdminLayout>
                <h1 className="font-bold text-5xl mb-5 text-center">{title}</h1>
                <div className="w-full text-center flex flex-row justify-center pt-10">
                    <LocalLoader></LocalLoader>
                </div>
            </AdminLayout>
        );
    }

    return (
        <AdminLayout>
            <h1 className="font-bold text-5xl mb-5 text-center">{title}</h1>
            <div className="grid grid-cols-3 gap-x-64">
                <BasicCard
                    label='Usuarios'
                    value={users}/>
                <BasicCard
                    label='Empresas'
                    value={companies}/>
            </div>
            <div className="grid grid-cols-3 gap-x-64">
                <BasicCard
                    label='Suscripciones Activas'
                    value={activeSubscriptions}
                    value2={cancelledCompanies > 0 ? `${(activeSubscriptions * 100 / (activeSubscriptions + cancelledCompanies)).toFixed(2)} %` : '0 %'}/>
                <BasicCard
                    label='Suscripciones inactivas'
                    value={cancelledCompanies}
                    value2={cancelledCompanies > 0 ? `${(cancelledCompanies * 100 / (activeSubscriptions + cancelledCompanies)).toFixed(2)} %` : '0 %'}/>
                <BasicCard
                    label='Suscripciones en uso'
                    value={companiesWithCampaigns}
                    value2={companiesWithCampaigns > 0 ? `${(companiesWithCampaigns * 100 / companies).toFixed(2)} %` : '0 %'}/>
                <BasicCard
                    label='FREE'
                    value={FreeSubscriptions}/>
                <BasicCard
                    label='BASIC'
                    value={BasicSubscriptions}/>
                <BasicCard
                    label='PRO'
                    value={ProSubscriptions}/>
                <BasicCard
                    label='Campañas'
                    value={campaigns}/>
                <BasicCard
                    label='Reacciones'
                    value={(campaigns && totalCountReactions) > 0 ? totalCountReactions : 0}/>
                <BasicCard
                    label='Predicciones'
                    value={predictions}
                />
            </div>
        </AdminLayout>
    );
};

export default Home;
