import LocalStorage, {ELocalStorageKeys} from './LocalStorage';
import {TAccessToken} from '../../types/TAccessToken';

class AuthStorage extends LocalStorage {
    private static _instance: AuthStorage;

    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    public saveAccessToken(value: TAccessToken): void {
        this.setItem(ELocalStorageKeys.accessToken, value.accessToken);
    }

    public accessToken(): TAccessToken|null {
        const token = this.getItem(ELocalStorageKeys.accessToken);
        if (!token) {
            return null;
        }
        return {
            accessToken: token,
        };
    }
}

export default AuthStorage.instance;
