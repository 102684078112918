import React, {useEffect, useState} from 'react';
import AdminLayout from '../../layouts/AdminLayout/AdminLayout';
import {LocalLoader} from '../../components/Common/Loader/Loader';
import TUser from '../../types/TUser';
import UsersTable from '../../components/Elements/UsersTables/UsersTable';
import Notification from '../../components/Common/Notification/Notification';
import UserService from '../../services/http/UserService';
import ExportData from '../../components/Common/ExportData/ExportData';

const Users = () => {
    const [users, setUsers] = useState<TUser[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const loadUsers = async () => {
        setIsLoading(true);
        try {
            const users= await UserService.list();
            if (users) {
                setUsers(users);
            }
        }
        catch (err: any) {
            Notification.displayException(err);
        }
        finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadUsers();
    }, []);

    const handleExportUsers = async () => {
        await UserService.report();
    };

    if (isLoading) {
        return (
            <AdminLayout>
                <div className="w-full text-center flex flex-row justify-center pt-10">
                    <LocalLoader></LocalLoader>
                </div>
            </AdminLayout>
        );
    }

    return (
        <AdminLayout>
            <div className="h-full px-5 relative">
                <h1 className="font-bold text-5xl mb-5 text-center">Usuarios</h1>
                <div className="overflow-y-auto scrollbar h-[750px]">
                    <UsersTable users={users} />
                </div>
                {
                    users.length > 0 && <ExportData className='absolute bottom-5' onClick={handleExportUsers} />
                }
            </div>
        </AdminLayout>
    );
};

export default Users;
